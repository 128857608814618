/************ Default Css ************/
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body #root {
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'IBM Plex Mono', monospace;
  color: rgb(213, 233, 247);
  /* font-weight: 400; */
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
}



@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}


/************ Navbar Css ************/
.logo a{
  font-size: 2.7rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-family: 'Sacramento', cursive;
  font-weight: 900;
  z-index: 9;
  padding-left: 30px;
}

.logo a:hover {
  opacity: 1;
  text-decoration: none;
  background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.logo img {
  width: 100px;
  padding-left: 25%;
}

nav.navbar {
   display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  padding: 18px 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation li {
    font-size: 0.8rem;
    list-style: none;
    padding: 10px 30px;
}

.navigation li:nth-child(5) {
  position: absolute;
  left: 100;
  margin-left:  45%;
}

.extra {
  width: 125px;
}

.navigation li a {
    color: rgba(56,135,190,1);
    font-family: 'Rubik', sans-serif;
    /* font-weight: 400; */
    letter-spacing: 1.3px;
    text-decoration: none;
     text-transform: uppercase;
    transition: color .3s;

}
.navigation li a:hover {
    /* background: #0072CE;
background: linear-gradient(to right, #0072CE 0%, #00D6BF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent; */
color: #fff;
    
}

nav.navbar.scrolled {
  padding: 10px 20px;
 background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* border:1px solid rgba(255, 255, 255, 0.18); */
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.menu-icon {
  /* background: 
    linear-gradient(#121212 0 0) padding-box,
    linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) border-box;
  color: white; */
     color: white;
padding: 0px 5px;
padding-bottom: 3px;
  /* border: 5px solid transparent; */
  /* border-radius: 10px; */
  padding-top: -100px;
  display: none;
  font-size: 1.7rem;
  margin-right: 30px;
}

nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
.items.active {
    color: #fff;
    border-bottom: 2px solid #fff;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    /* background: rgba(217, 217, 217, 0.1); */
    background-color: rgba(255, 255, 255, 0.9);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a .img {
	width: 50%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 12px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
/* button.now::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
} */
/* .navbar-text button:hover {
  color: #121212;
} */
/* button.now:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
} */
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}



.topBlur {
  position: absolute;
  width: 30vw;
  height: 30vw;
  min-width: 35px;
  min-width: 35px;
  top: -128px;
  left: -10vw;
  border-radius: 764px;
 background: rgba(56,135,190, 0.5);
  filter: blur(150px);
  z-index: 0;
}

.bottomBlur {
  position: absolute;
  width: 70vw;
  height: 20vw;
  min-width: 350px;
  min-width: 250px;
  top: 246px;
  right: -25vw;
  border-radius: 764px;
  background: rgba(56,135,190, 0.5);
  background: linear-gradient(98deg, rgba(56,135,190, 0.5) 22%, rgba(48,227,202,0.5) 100%);
  filter: blur(150px);
  z-index: 0;
}

.sign-up {
  display: flex;
  /* width: 100%; */
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.sign-up {
  background-image: url('https://cdn.pixabay.com/photo/2016/05/17/18/59/hyacinth-1398821_1280.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Hero */

 .hero-container {
  /* background-image: url("img/Ai.png");
  background-position: right;
  background-size: 70%;
  background-repeat: no-repeat; */
  /* width: 80%; */
  display: flex;
  width: 100vw;
   height: 90vh;
  align-items: center;
  justify-content: space-around;
  /* position: relative; */
  /* background-image: url('./img/bgpic1.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
  padding-left: 2%;
   margin-top: 7%;
  /* filter: blur(80%); */
  /* flex-direction: row; */
}

.hero-img {
  width: 100vw;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* margin-right: 10%; */
  margin-top: 5%;
  
} 

/* .hero-container::before {
  content: "";
    position: absolute; top: 0; left: 0;
    background: url('./img/bgpic1.jpg') center center/cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.35;
} */

.hero {
  /* color: #fff; */
  /* font-size: 100px; */
  /* display: flex; */
  /* margin-top: 15%; */
}

p.hero-top {
        font-family: 'Saira', sans-serif;
        color: #999;
        letter-spacing: 20px;
        font-size: 0.8rem;
        text-align: center;
        margin-left: 1%;
}

p.hero-bottom {
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'IBM Plex Mono', monospace;
  color: rgb(213, 233, 247);
    text-align: center;
  font-size: 1.1rem;
}

p.hero-bottom b{
    color: #fff;
}

.main-heading {
  /* background: #FFFFFF;
  background: repeating-linear-gradient(to bottom, #FFFFFF 54%, #840407 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
      text-align: center;
   background: #3887BE;
background: linear-gradient(to bottom, #3887BE 14%, #FFFFFF 50%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

    font-family: 'Nova Square', sans-serif;
    font-weight: 700;
    font-size: 3.3rem;
    letter-spacing: 1px;
    text-shadow: 2px 3px 20px rgba(255,255,255,0.5);
}

/* .divGlass {
  position: relative;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100vw;
    height: 200px;
    top: -10vh;
    left: 0;
} */

.middle-img {
  width: 70vw;
  margin: 0 15%;
  margin-bottom: 0;
  margin-top: 0;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/* HomeDiv */

.containerHm {
  /* position: relative; */
/* background: linear-gradient(135deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
    /* border:1px solid rgba(255, 255, 255, 0.18); */
    border: 1px solid rgba(165, 215, 232, 0.42);
    box-shadow: 0 6px 30px rgba(165, 215, 232, 0.3);
  background-color: rgba(12, 12, 12, 0.6);
  border-radius: 64px;
  padding: 73px;
  margin-top: 129px;
  z-index: 1;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 15%;
  padding-right: 5%;
   display: flex;
  flex-direction: row;
  align-items: center;
}

.aboutImage {
  width: 45%;
}

.aboutItems {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.aboutItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  list-style: none;
  /* border: 1px solid rgba(165, 215, 232, 0.42); */
  box-shadow: 0 15px 30px rgba(255,255,255,0.210);
  padding: 25px;
  background-image: linear-gradient(
    90deg,
    rgba(165, 215, 232, 0.42) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
}

.aboutItem:hover {
  background-size: 100% 100%;
}

.aboutItem h2, .skill h2 {
  font-family: 'Saira', sans-serif;
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: 600;
  background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

.aboutItem p, .skill p {
  font-size: 18px;
  letter-spacing: 0.8px;
}

/************ AiApps Css ************/
.project {
  margin: 0 auto ;
  padding: 0;
  margin-top: 10%;
  /* padding-bottom: 80px; */
  position: relative;
  background-color: transparent;
}
.project h2, .pxp-section-h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  font-family: 'Saira', sans-serif;
  font-size: 2.6rem;
  line-height: 3rem;
  background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.project p {
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 90%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  text-decoration: none;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, rgba(56,135,190,1) -5.91%, rgba(48,227,202,1) 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 10%;
}

/* .col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
} */

/* .proj-imgbx img {
   width: 100%;
  height: 100%;
  object-fit: cover;
} */
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, rgba(56,135,190,0.7) -5.91%, rgba(48,227,202, 0.7) 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  background-color: #121212;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-blur-left {
  /* top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: 0;
  mix-blend-mode: color; */
  position: absolute;
  width: 20vw;
  height: 20vw;
  min-width: 30px;
  min-width: 30px;
  top: 20%;
  left: -10vw;
  border-radius: 764px;
 background: rgba(56,135,190, 0.5);
  filter: blur(150px);
  z-index: 0;
}

.background-blur-right {
    position: absolute;
  width: 40vw;
  height: 20vw;
  min-width: 350px;
  min-width: 250px;
  bottom: -20vh;
  right: -25vw;
  border-radius: 764px;
  background: rgba(56,135,190, 0.5);
  background: linear-gradient(98deg, rgba(56,135,190, 0.5) 22%, rgba(48,227,202,0.5) 100%);
  filter: blur(150px);
  z-index: 0;
}


/************ AiTools Css ************/
.skill {
  padding: 0 0 50px 0;
  margin-bottom: 10%;
  position: relative;
}
.skill-bx {
  /* background: #151515; */
  border: 1px solid rgba(165, 215, 232, 0.42);
    box-shadow: 0 6px 30px rgba(165, 215, 232, 0.3);
  background-color: rgba(12, 12, 12, 0.6);
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
/* .skill h2 {
	font-size: 45px;
	font-weight: 700;
} */
.skill p {
    /* color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px; */
    line-height: 1em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-tools-left {
  position: absolute;
  width: 20vw;
  height: 20vw;
  min-width: 30px;
  min-width: 30px;
  bottom: -30%;
  left: -10vw;
  border-radius: 764px;
 background: rgba(56,135,190, 0.5);
  filter: blur(150px);
  z-index: 0;
}

/* Comparison Css */

Link {
  text-decoration: none;
}

.comparison {
  margin-top: 10%;
  margin-bottom: 10%;
  position: relative;
}

.pxp-plans-1-item {
    display: block;
    margin-bottom: 30px;
    text-align: center;
    padding: 60px;
    border-radius: 3rem;
    border: 2px solid transparent;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
     text-decoration: none;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item:hover {
    border: 2px solid rgba(165, 215, 232, 0.42);
    text-decoration: none;
}
.pxp-plans-1-item.pxp-is-popular {
    background-color: rgba(12, 12, 12, 0.6);
    box-shadow: 0px 15px 30px rgba(255,255,255,0.20);
}

.pxp-plans-1-item-fig {
    margin-bottom: 2rem;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item .pxp-plans-1-item-fig {
    margin-top: calc(26px + 1rem);
}
.pxp-plans-1-item.pxp-is-popular .pxp-plans-1-item-fig {
    margin-top: 0;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-fig {
    transform: translateY(-42px) scale(.9);
}
.pxp-plans-1-item-fig .img {
    color: rgb(255 255 255);
    font-size: 100px;
    height: 100px;
    width: auto;
}
.pxp-plans-1-item-title {
    font-weight: 700;
   /* color: rgb(213, 233, 247); */
   background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item.pxp-is-popular .pxp-plans-1-item-title {
  background: #3887BE;
  background: linear-gradient(to bottom, #3887BE 24%, #FFFFFF 50%, #30E3CA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-title {
    transform: translateY(-42px) scale(1.3);
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}
.pxp-plans-1-item-features {
    margin-bottom: 2rem;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-features {
    transform: translateY(-42px);
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}
.pxp-plans-1-item-features > li {
    opacity: .7;
    color: #e8e8e8;
}
.pxp-plans-1-item-price {
    line-height: 2rem;
    text-decoration: none;
    color: rgb(213, 233, 247);
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-price {
    transform: translateY(-42px);
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}
.pxp-plans-1-item-price-currency {
    font-size: .7rem;
    font-weight: 900;
    vertical-align: top;
    line-height: 1.2rem;
}
.pxp-plans-1-item-price-sum {
    font-size: 2rem;
    font-weight: 900;
    
}
.pxp-plans-1-item-price-period {
    opacity: 1;
    font-size: .8rem;
}
.pxp-plans-1-item-label {
    color: #121212;
   background: 
        linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) padding-box,
        linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) border-box;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px 10px;
    border-radius: 50px;
    font-size: .6rem;
    display: inline-block;
    margin-bottom: 1rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-label {
    transform: translateY(-42px);
    opacity: 0;
}
.pxp-plans-1-item-cta {
    position: absolute;
    color: #e8e8e8;
    bottom: 60px;
    left: 50%;
    z-index: 3;
    font-size: 0.7rem;
    letter-spacing: 1px;
    opacity: 0;
    font-weight: 700;
    transform: translate(-50%, 200%);
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-cta {
    transform: translate(-50%, 0);
    opacity: 1;
    -webkit-transition-delay: .4s;
    transition-delay: .4s;
}
.pxp-is-popular .pxp-plans-1-item-price-sum {
    background: #3887BE;
  background: linear-gradient(to bottom, #3887BE 24%, #FFFFFF 50%, #30E3CA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

/* HeroOther Css */

.pxp-cta-4 {
    /* background: transparent url("./img/7SUp.gif") top center/100vw 100vh no-repeat; */
    /* padding-top: 200px; */
    padding-bottom: 100px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    display: flex;
     justify-content: space-around;
     align-items: center;
}


.cta-background {
    width: 70vw;
  margin: 0 auto;
      /* width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      z-index: -1;
      mix-blend-mode: darken; */
}

.hero-other h2 {
    font-family: 'Saira', sans-serif;
    font-weight: 700;
    font-size: 2.8rem;
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    text-align: left;
}

.hero-other p {
  text-align: left;
}

.pxp-text-light {
    opacity: .7;
}
.pxp-primary-cta {
    color: #30E3CA;
    font-weight: 300;
     font-family: 'Rubik', sans-serif;
    display: inline-block;
    text-decoration: none;
    font-size: 1.5rem;
}
.pxp-primary-cta:hover {
    color: #999;
    text-decoration: none;
}
.pxp-primary-cta:after {
    content: " ";
    border-top: 2px solid #999;
    width: 36px;
    height: 7px;
    display: inline-block;
    margin-left: 20px;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    transform-origin: top left;
}
.pxp-primary-cta.text-white:after {
    border-top: 2px solid #fff;
}
.pxp-primary-cta:hover:after {
    transform: scale(2, 1);
}

.pxp-animate {
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

/* Decade */

.decade {
  /* background-image: url("./img/7.png");  */
  /* background-position: 50% 50%; */
  margin-top: 10% ;
  margin-bottom: 10% ;
padding-left: 7%;
padding-right: 7%;
  position: relative;
}

.pxp-services-container {
    height: 300px;
    border: 1px solid rgba(165, 215, 232, 0.42);
    box-shadow: 0 6px 30px rgba(165, 215, 232, 0.3);
  background-color: rgba(12, 12, 12, 0.6);
    /* box-shadow: 0px 15px 30px rgba(0,0,0,0.10); */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pxp-services-item {
    display: block;
    background-color: #121212;
    position: relative;
    border-right: 1px solid rgba(165, 215, 232, 0.42);
    overflow: hidden;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.pxp-services-item:first-child {
    border-radius: .3rem 0 0 .3rem;
}
.pxp-services-item:nth-child(4) {
    border-right: 0 none;
    border-radius: 0 .3rem .3rem 0;
}
.pxp-services-item-fig {
    width: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
}
.pxp-services-item-fig img {
    width: 100%;
    height: auto;
}
.pxp-services-item-text {
    position: absolute;
    top: 160px;
    left: 20px;
    right: 20px;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-services-item-text-title {
    font-family: 'Saira', sans-serif;
    font-weight: 900;
    font-size: 1.3rem;
    margin-bottom: .5rem;
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.pxp-services-item-text-sub {
    color: rgb(213, 233, 247);
    font-size: .8rem;
}
@keyframes iconTop { 
    0% {
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    25% {
        opacity: 0;
        -webkit-transform: translate(-50%, -70%);
        transform: translate(-50%, -70%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translate(-50%, -40%);
        transform: translate(-50%, -40%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}
.pxp-services-item:hover .pxp-services-item-fig {
    -webkit-animation: iconTop 0.4s ease-in-out;
    -moz-animation: iconTop 0.4s ease-in-out;
    animation: iconTop 0.4s ease-in-out;
}
.pxp-services-item:hover .pxp-services-item-text {
    transform: translateY(-50%);
}
.pxp-services-item-cta {
    position: absolute;
    color: #fff;
    bottom: 40px;
    left: 20px;
    right: 20px;
    z-index: 3;
    font-size: 0.7rem;
    letter-spacing: 1px;
    opacity: 0;
    font-weight: 700;
    transform: translateY(400%);
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pxp-services-item:hover .pxp-services-item-cta {
    transform: translateY(0);
    opacity: 1;
}
/* Footer */


.pxp-footer {
    /* background-color: #000; */
    background: transparent;
    /* margin-top: 20%; */
    margin-bottom: 7%;
}
.pxp-footer-logo {
   font-size: 1.8rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-family: 'Sacramento', cursive;
  font-weight: 900;
  z-index: 9;
}

.pxp-footer-logo:hover {
  text-decoration: none;
  background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.pxp-footer-address {
    opacity: .7;
    font-size: .9rem;
    line-height: 1.8;
}

.pxp-footer-social a {
    color: rgb(213, 233, 247);
    font-size: 1.6rem;
    margin-right: 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.pxp-footer-social a:hover {
    opacity: .7;
}
.pxp-footer-header {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 42px;
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.pxp-footer-links a {
    font-size: .9rem;
    text-decoration: none;
    color: rgb(213, 233, 247);
    
    line-height: 1.8;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.pxp-footer-links a:hover {
    opacity: .7;
    text-decoration: none;
}
.pxp-footer-bottom {
    font-size: .8rem;
    line-height: 1.8;
    color: rgb(213, 233, 247);
}
.pxp-content-side-wrapper .pxp-footer-bottom {
    margin-top: 0;
}
.pxp-footer-bottom a {
    color: rgb(213, 233, 247);
    text-decoration: none;
}
.pxp-footer-bottom a:hover {
    opacity: .7;
}
.pxp-footer-copyright {
    opacity: .7;
}
.pxp-content.pxp-full-height {
    margin-top: 69px;
}

/* Support Css */

.pxp-services-h {
    background: linear-gradient(to right, #121212 50%, rgb(213, 233, 247) 50%);
}

.pxp-services-h-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pxp-services-h-fig {
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
    height: 540px;
    background-image: url("https://images.unsplash.com/photo-1585858229735-cd08d8cb510d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3VwcG9ydGVkJTIwYnklMjB3b3JsZHdpZGV8ZW58MHx8MHx8fDA%3D");
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
}

.pxp-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.pxp-services-h-fig.pxp-animate-in {
    transform: translateX(50px);
    opacity: 0;
    -webkit-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}
.pxp-services-h-fig.pxp-animate-in.pxp-in {
    transform: translateX(0);
    opacity: 1;
}
.pxp-services-h-items {
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}
.pxp-services-h-items.pxp-animate-in .pxp-services-h-item {
    transform: translateX(50px);
    opacity: 0;
    -webkit-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item {
    transform: translateX(0);
    opacity: 1;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item:nth-child(1) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item:nth-child(2) {
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item:nth-child(3) {
    -webkit-transition-delay: 0.8s;
    transition-delay: 0.8s;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item:nth-child(4) {
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
}
.pxp-services-h-items.pxp-animate-in.pxp-in .pxp-services-h-item:nth-child(5) {
    -webkit-transition-delay: 1.2s;
    transition-delay: 1.2s;
}
.pxp-services-h-item img {
    height: 64px;
    width: auto;
}
.pxp-services-h-item .media-body {
    color: #999;
}
.pxp-services-h-item h5 {
    font-weight: 900;
    color: #333;
    font-size: 1.3rem;
    margin-bottom: .5rem;
}
.pxp-services-h .pxp-primary-cta.pxp-animate-in {
    transform: translateX(50px);
    opacity: 0;
    -webkit-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}
.pxp-services-h .pxp-primary-cta.pxp-animate-in.pxp-in {
    transform: translateX(0);
    opacity: 1;
}
/* About Upow Css */

.pxp-content-wrapper {
    padding-top: 82px;
}

.pxp-blog-posts {
    padding-top: 82px;
}

.pxp-blog-post-category {
    color: #E2E2E2;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10%;
    opacity: .7;
}
.pxp-blog-post-category > span {
    display: inline-block;
}
.pxp-blog-post-category > span:first-child {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(255, 255, 255, .5);
}

.blog-post-heading {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 7% 7% 0 7%;
}

.pxp-blog-post-hero-fig {
    height: 80vh;
    min-height: 540px;
    width: 40vw;
    border-radius: 100%;
    /* border: 1px solid rgba(165, 215, 232, 0.42); */
    /* background-image: url("./img/U-crypto-logo.png"); */
    background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15%;
  /* box-shadow: 0 15px 30px 10px rgba(165, 215, 232, 0.3); */
}
.pxp-blog-post-block h2, 
.pxp-blog-post-block h3, 
.pxp-blog-post-block h4, 
.pxp-blog-post-block h5, 
.pxp-blog-post-block h6,
.pxp-agent-block h2, 
.pxp-agent-block h3, 
.pxp-agent-block h4, 
.pxp-agent-block h5, 
.pxp-agent-block h6 {
    font-weight: 900;
    margin-bottom: 1rem;
    font-family: 'Rubik', sans-serif;
    background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

h1.pxp-page-header {
  background: #3887BE;
background: repeating-linear-gradient(to bottom, #3887BE 0%, #30E3CA 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    font-family: 'Saira', sans-serif;
    font-weight: 900;
    font-size: 3rem;
    line-height: 3rem;
}

.blog-post-heading p {
  margin-top: 5%;
}

.pxp-agent-block h4 {
    margin-bottom: 0;
}
.pxp-blog-post-block p {
    color: rgb(213, 233, 247);
    
}
.pxp-blog-post-block p.pxp-first-letter:first-letter {
    color: #fff;
    float: left;
    font-size: 85px;
    line-height: 60px;
    padding-right: 10px;
    font-weight: 700;
    padding-top: 7px;
}
.pxp-blog-post-block,
.pxp-agent-block {
    width: 80%;
    margin: 0 auto;
}

.blog-container{
  margin-top: 500px;
}
.pxp-blog-post-block.pxp-full {
    width: 100%;
}
.pxp-blog-post-share {
    width: 65px;
    text-align: center;
}
.pxp-blog-post-share-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: .8rem;
}
.pxp-blog-post-share > ul > li {
    padding-bottom: 10px;
}
.pxp-blog-post-share > ul > li > a {
    display: inline-block;
    padding-top: 5px;
    border-radius: 50%;
    border: 1px solid rgb(213, 233, 247);
    color: rgb(213, 233, 247);
    width: 36px;
    height: 36px;
    text-align: center;
    font-size: 25px;
    line-height: 14px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.pxp-blog-post-share > ul > li > a:hover {
    background-color: #fff;
    color: #121212;
}
.pxp-blog-post-blockquote {
    font-size: 1.3rem;
    font-weight: 700;
    width: 60%;
    float: left;
    border-left: 2px solid rgb(213, 233, 247);
    padding: 0 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: calc((100% - 1140px) / 2 + 15px);
     background: #3887BE;
  background: linear-gradient(to bottom, #3887BE 24%, #FFFFFF 50%, #30E3CA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background-learn-right {
    position: absolute;
  width: 40vw;
  height: 20vw;
  min-width: 350px;
  min-width: 250px;
  bottom: 75vh;
  right: -25vw;
  border-radius: 764px;
  background: rgba(56,135,190, 0.5);
  background: linear-gradient(98deg, rgba(56,135,190, 0.5) 22%, rgba(48,227,202,0.5) 100%);
  filter: blur(150px);
  z-index: 0;
}

hr.seperator {
  margin: auto;
  color: rgb(213, 233, 247);
  border-style: dotted;
  border-top: 0;
  width: 6%;
  border-width: 6px;
  opacity: 0.5;
}

/* Para Css */
div.pxp-cta-3:nth-child(2) {
  margin-bottom: 10%;
}

div.pxp-cta-3:nth-child(1) {
  margin-top: 10%;
  margin-bottom: 10%;
  margin-right: 10%;
}

.pxp-cta-3-image {
    width: 500px;
}

#pxp-cta-3-first h2, #pxp-cta-3-first p {
  text-align: right;
}
#pxp-cta-3-second h2, #pxp-cta-3-second p{
  text-align: left;
}


/* Media Queries */
@media screen and (max-width: 1199px) {
.pxp-blog-post-hero {
        padding-left: calc((100% - 960px) / 2 + 15px);
    }

.hero-other {
  margin-top: -10%;
} 

.pxp-services-container {
        display: block;
        height: auto;
        transform: none;
    }

.pxp-services-item {
        width: 50%;
        height: 300px;
        float: left;
    }
    .pxp-services-item:nth-child(1) {
        border-bottom: 1px solid rgb(213, 233, 247);
        border-radius: .3rem 0 0 0;
    }
    .pxp-services-item:nth-child(2) {
        border-right: 0 none;
        border-radius: 0 .3rem 0 0;
        border-bottom: 1px solid rgb(213, 233, 247);
    }
    .pxp-services-item:nth-child(3) {
        border-radius: 0 0 0 .3rem;
    }
    .pxp-services-item:nth-child(4) {
        border-radius: 0 0 .3rem 0;
        border-right: 0 none;
    }
  }

@media screen and (max-width: 1139px) {
.navigation li {
    font-size: 0.9rem;
    list-style: none;
    padding: 10px 20px;
}

  .pxp-blog-post-blockquote {
        margin-left: 0;
    }

  .pxp-cta-4 {
         flex-direction: column-reverse;
  }
  .cta-background {
    width: 50vw;
    margin-bottom: 5%;
  }
  p.hero-top {
    font-size: 0.7rem;
  }
  .main-heading {
    font-size: 2.8rem;
  }
  h1.pxp-page-header {
    font-size: 2.5rem;
  }
  .proj-txtx span {
    font-size: 0.9rem;
  }
    .proj-txtx h4 {
    font-size: 25px;
  }
}

@media screen and (max-width: 960px) {
.menu-icon {
  display: inline-block;
  position: absolute;
    top: 20%;
    right: 1%;
}

.logo img {
  width: 90px;
}

/* button.now{
  display: none;
  position: absolute;
    top: 70vh;
    left: 20%;
} */

.navigation li:nth-child(5) {
  position: static;
  margin-left: 0;
}

.extra {
  display: none;
}

.navigation {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    border-radius: 20px;
    height: 45vh;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6));
    backdrop-filter: blur(10px);
    padding-top: 60px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    animation-name: example;
    animation-duration: 1s;
    transition: all 0.5s ease-in-out;
  }

  @keyframes example {
     0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
  }
}
.navigation li {
    list-style: none;
    padding: 10px 30px;
}

.navigation li a {
    color: rgba(56,135,190,1);
    font-weight: 600;
    text-decoration: none;
    transition: color .3s;

}

.containerHm {
  padding-left: 0;
  flex-direction: column;
  }

      .pxp-blog-post-block,
    .pxp-agent-block {
        width: 100%;
        margin: 0;
    }
    .pxp-blog-post-share {
        width: 100%;
        text-align: left;
    }
    .pxp-blog-post-share > ul > li {
        float: left;
        margin-right: 10px;
    }
    .pxp-blog-post-share-label {
        float: left;
        margin-right: 20px;
        line-height: 36px;
    }

 .pxp-blog-post-hero {
        padding: 0 10%;
    }

  .pxp-blog-post-hero-fig {
        height: 100vh;
        width: 60vw;
        min-height: auto;
    }
.main-heading {
  font-size: 2.5rem;
}
  h1.pxp-page-header {
    font-size: 2.2rem;
  }

    .pxp-hero-caption p.hero-bottom, .blog-post-heading p, .pxp-blog-post-block p{
        font-size: 0.9rem;
    }
p.hero-bottom {
  text-wrap: balance;
}

    .pxp-hero-caption p.hero-top {
        font-size: 0.6rem;
        margin-left: 1%;
    }
    .hero-container {
      flex-direction: column-reverse;
      margin-bottom: 30%;
    }
    .hero-img {
        width: 70vw;
        margin: 0 auto;
        padding: 0 auto;
    }

    .pxp-services-h {
        background: #000;
    }
    .pxp-services-h-container {
        display: block;
    }
    .pxp-services-h-fig {
        height: 240px;
    }

    .pxp-services-h-item .media-body {
        color: #8c8c8c;
    }
.pxp-services-h-item h5 {
        color: #fff;
    }
.pxp-services-c-content .owl-carousel .owl-nav button svg line {
        stroke: #fff;
    }
.pxp-services-h .pxp-primary-cta {
        color: #fff;
    }
.pxp-services-h .pxp-primary-cta:after {
        border-top: 2px solid #fff;
}
 .pxp-services-item {
        width: 100%;
        float: none;
        border-right: 0 none;
        border-bottom: 1px solid rgb(213, 233, 247);
    }

.pxp-services-item:nth-child(1) {
        border-radius: .3rem .3rem 0 0;
    }
    .pxp-services-item:nth-child(2) {
        border-radius: 0;
    }
    .pxp-services-item:nth-child(3) {
        border-radius: 0;
    }
    .pxp-services-item:nth-child(4) {
        border-radius: 0 0 .3rem .3rem;
    }

  .blog-post-heading {
    flex-direction: column;
    align-items: center;
  }
  .proj-txtx h4 {
    font-size: 23px;
  }

  div.pxp-cta-3:nth-child(1) {
  margin-left: 10%;
}
}

@media screen and (max-width: 805px) {
  
.logo a {
  font-size: 2.3rem;
  padding-left: 20px;
}

.aboutItem h2, .project h2, .skill h2, .hero-other h2 {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  
.aboutItem p, .project p, .skill p{
  font-size: 1rem;
  line-height: 1.2rem;
  
}

.aboutItem p, .project p, .skill p {
  width: 97%;
}
.proj-txtx h4 {
  font-size: 18px;
}

.middle-img {
  margin-bottom: 10%;
}

.containerHm {
  padding-top: 9%;
  margin-left: 10%;
  margin-right: 10%;
}

.aboutImage {
  width: 65%;
}
.skill-slider .item img {
  width: 80%;
}

 .pxp-plans-1-item {
        text-decoration: none;
       border: 2px solid #000;
    }
    .pxp-plans-1-item .pxp-plans-1-item-fig {
        transform: translateY(-42px) scale(.9);
    }
    .pxp-plans-1-item .pxp-plans-1-item-title {
        transform: translateY(-42px) scale(1.3);
        -webkit-transition-delay: .1s;
        transition-delay: .1s;
    }
    .pxp-plans-1-item .pxp-plans-1-item-features {
        transform: translateY(-42px);
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
    }
    .pxp-plans-1-item .pxp-plans-1-item-price {
        transform: translateY(-42px);
        -webkit-transition-delay: .3s;
        transition-delay: .3s;
    }
    .pxp-plans-1-item .pxp-plans-1-item-label {
        transform: translateY(-42px);
        opacity: 0;
    }
    .pxp-plans-1-item .pxp-plans-1-item-cta {
        transform: translate(-50%, 0);
        opacity: 1;
        -webkit-transition-delay: .4s;
        transition-delay: .4s;
    }
 .pxp-blog-post-hero {
       padding: 0 10%;
    }

   .pxp-blog-post-blockquote {
        padding: 0 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1rem;
    }
    .pxp-hero-caption.pxp-hero-caption-bottom-left.pxp-is-small {
        bottom: 0;
    }
     .pxp-hero-caption.pxp-hero-caption-bottom-left {
        bottom: 60px;
    }
    .pxp-hero {
        height: 600px !important;
    }
     .pxp-hero-caption h1 {
        font-size: 2rem;
    }

  .cta-background {
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 575px) {
.logo a {
  font-size: 2rem;
  padding-left: 10px;
}

  .pxp-hero {
        height: 560px !important;
        min-height: 560px;
    }
    .pxp-hero-caption.pxp-hero-caption-bottom-left {
        right: 0;
        left: 0;
    }

 .pxp-blog-post-hero {
        padding-left: 15px;
        padding-right: 15px;
    }
.pxp-blog-post-hero-fig {
  background-size: contain;
  height: 50vh;
}
 .pxp-blog-post-blockquote {
        float: none;
        width: 100%;
        font-size: 0.9rem;
    }

.project h2, .pxp-section-h2, .aboutItem h2, .skill h2, .hero-other h2, .pxp-blog-post-block h2, .proj-txtx h4 {
  font-size: 1.4rem;
  /* line-height: 2.6rem; */
  }

.hero-other {
  margin-top: -10%;
}
 .pxp-cta-3-image {
        width: 350px;
    }
.pxp-blog-post-main {
  margin-left: 7%;
  margin-right: 7%;
}

 .pxp-hero-caption .main-heading {
  font-size: 1.8rem;
}
  h1.pxp-page-header {
    font-size: 1.5rem;
  }

 .pxp-hero-caption p.hero-top {
        letter-spacing: 10px;
        font-size: 0.45rem;
}

.blog-post-heading p, .proj-txtx span, .aboutItem p, .project p, .skill p, .pxp-hero-caption p.hero-bottom, .pxp-blog-post-block p, .pxp-cta-3 p{
  font-size: 0.75rem;
}
.project .nav.nav-pills .nav-link {
  font-size: 0.7rem;

}
.middle-img {
  margin-bottom: 15%;
}

 .pxp-cta-3 h2 {
  font-size: 1.2rem;
  line-height: normal;
 }
 .pxp-cta-3 p,  .pxp-cta-3 h2  {
width: 90%;
padding-left: 15px;
}
.item h5 {
  font-size: 1rem;
}
div.pxp-cta-3:nth-child(2) {
  margin-bottom: 20%;
}
div.pxp-cta-3:nth-child(1) {
  margin-right: 0;
  margin-left: 2%;
}
}

