button.now {
  background: 
    linear-gradient(#121212 0 0) padding-box, /*this is background*/
    linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) border-box;
  color: white;
padding: 10px 30px;
  border: 5px solid transparent;
  border-radius: 20px;
  display: inline-block;
  margin-right: 50px;
  letter-spacing: 0.5px;
  transition: all .4s ease-in-out;
}

button.now:hover {
    transform: scale(1.1);
    background: 
        linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) padding-box,
        linear-gradient(98deg, rgba(56,135,190,1) 22%, rgba(48,227,202,1) 100%) border-box;

}
